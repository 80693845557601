import qs from 'qs';
import { BASE_PATH } from 'shared/constants/constants';
import {
  IApprovalParams,
  IGetApprovalDto,
  IGetApprovalRowDtoWithSuggestions,
  IGetPartialApprovalSetsDto,
  IPostApprovalDto,
  IPutApprovalDto,
  IPutApprovalStatusDto,
} from 'shared/interfaces';

import HTTPService from './HttpService';

class ApprovalService extends HTTPService {
  constructor() {
    super(BASE_PATH.APPROVALS);
  }

  public getApprovals(params: IApprovalParams): Promise<IGetApprovalDto[]> {
    return this.GET('', {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  }

  public getApprovalRows(
    id: number,
    partialApprovalRowId: number,
    openAsSummaryApproval: boolean,
    showAllSuggestions: boolean
  ): Promise<IGetApprovalRowDtoWithSuggestions> {
    return this.GET(`${id}/rows`, { params: { partialApprovalRowId, openAsSummaryApproval, showAllSuggestions } });
  }

  public getApproval(id: number): Promise<IGetApprovalDto> {
    return this.GET(`${id}`);
  }

  public postApproval(body: IPostApprovalDto): Promise<number> {
    return this.POST('', body);
  }

  public putApproval(id: number, body: IPutApprovalDto): Promise<void> {
    return this.PUT(`${id}`, body);
  }

  public putApprovalStatus(id: number, body: IPutApprovalStatusDto): Promise<void> {
    return this.PUT(`${id}/status`, body);
  }

  public getPartialApprovalSets(id: number, partialApprovalId?: number | null): Promise<IGetPartialApprovalSetsDto> {
    return this.GET(`${id}/partial-approval-sets`, { params: { partialApprovalId } });
  }

  public deleteApproval(id: number): Promise<void> {
    return this.DELETE(`${id}`);
  }
}

export default new ApprovalService();
