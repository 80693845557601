import { GridRowsProp } from '@mui/x-data-grid-pro';
import { makeAutoObservable, runInAction } from 'mobx';
import { ApprovalRowSuggestionService, ApprovalService, ParameterGroupService } from 'services';
import { Translator, approvalStore, partialApprovalStore, toastStore, usersStore } from 'stores';
import { HIDE_NAVIGATION__APPROVAL_ROW_WIDTH } from 'shared/constants/constants';
import { RowStructureType } from 'shared/enums';
import { Routes } from 'shared/enums/Routes';
import {
  IApprovalRow,
  IApprovalRowTree,
  IGetApprovalRowDto,
  IGetParameterGroupParametersDto,
  IParameterSuggestion,
  IPutRowSuggestionSetAcceptedDto,
} from 'shared/interfaces';
import { ApprovalRowSuggestionModel, FilterParameterModel } from 'shared/models';
import Utils from 'shared/utils/Utils';

class ApprovalSuggestionRowStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public isLoadingParameter = false;

  public isUpdate = false;

  public approvalRowListDto: IGetApprovalRowDto[] = [];

  public approvalRows: IApprovalRow[] = [];

  public canGoToOnApprovalSkippingIterations: boolean | null = null;

  public approvalSuggestionRow = new ApprovalRowSuggestionModel();

  public filterParameters = new FilterParameterModel();

  public parametersSuggestion: IParameterSuggestion[] = [];

  public expandedKeys: number[] = [];

  public checkedNavigationTreeIds = new Set<number>();

  public checkedApprovalRowIds = new Set<number>();

  public selectedTreeId: number | null = null;

  public isSuggestionDialogOpen = false;

  public isCreateValueDialogOpen = false;

  public isHideApproval = false;

  public isShowAllSuggestions = true;

  public hasChangeSuggestion = false;

  public conflictApprovalRowSuggestionIds: number[] = [];

  public navigationWidth = HIDE_NAVIGATION__APPROVAL_ROW_WIDTH;

  public get allAcceptedTreeIds(): number[] {
    return Array.from(this.checkedNavigationTreeIds);
  }

  public get allApprovalRowIds(): number[] {
    return Array.from(this.checkedApprovalRowIds);
  }

  public get filteredParamSuggestionTableData(): GridRowsProp {
    const groupIds = this.parametersSuggestion
      .filter((parameter) => parameter.fullName.toLowerCase().includes(this.filterParameters.searchPattern.toLowerCase()))
      .map((data) => data.groupId!);

    const uniqueGroupIds = new Set(groupIds);

    return this.parametersSuggestion.filter(
      (parameter) => parameter.fullName.toLowerCase().includes(this.filterParameters.searchPattern.toLowerCase()) || uniqueGroupIds.has(parameter.id!)
    );
  }

  public get filteredApprovalIterationRows(): IApprovalRow[] {
    if (this.isHideApproval) {
      return this.approvalRows.filter((f) => (f.isGroup && !f.isNotHeaderPartial) || (!f.isGroup && f.hasPartialApprovalId));
    }

    return this.approvalRows;
  }

  public get filteredApprovalRowsTree(): IApprovalRowTree[] {
    return this.createApprovalRowTree(this.approvalRowListDto);
  }

  public setNavigationWidth(value: number) {
    this.navigationWidth = value;
  }

  public setChangeSuggestionStorage(hasChangeSuggestion: boolean) {
    localStorage.setItem('hasChangeSuggestion', JSON.stringify(hasChangeSuggestion));
  }

  public setShowAllSuggestions(value: boolean) {
    this.isShowAllSuggestions = value;
  }

  public getChangeSuggestionStorage() {
    if (localStorage.getItem('hasChangeSuggestion')) {
      const storageValue = JSON.parse(localStorage.getItem('hasChangeSuggestion')!);

      if (this.hasChangeSuggestion !== storageValue) {
        this.changeSuggestion(storageValue);
      }
    } else {
      this.changeSuggestion(this.hasChangeSuggestion);
    }
  }

  public changeSuggestion(hasChangeSuggestion: boolean) {
    this.hasChangeSuggestion = hasChangeSuggestion;
    this.setChangeSuggestionStorage(hasChangeSuggestion);
  }

  public clearParametersSuggestion() {
    this.parametersSuggestion
      .filter((f) => f.value !== '' || f.parameterListValues!.length > 0)
      .forEach((parameter) => {
        parameter.value = '';
        parameter.parameterListValues = [];
      });
  }

  public setSuggestionDialogDialogOpen(isOpen: boolean) {
    this.isSuggestionDialogOpen = isOpen;
  }

  public setCreateValueDialogOpen(isOpen: boolean) {
    this.isCreateValueDialogOpen = isOpen;
  }

  public setHideApproval(isHideApproval: boolean) {
    this.isHideApproval = isHideApproval;
  }

  public setExpandedKeys(expandedKeys: number[]) {
    this.expandedKeys = expandedKeys;
  }

  public setSelectedTreeId(selectedTreeId: number | null) {
    this.selectedTreeId = selectedTreeId;
  }

  public setCheckedGroupParameters(checkedKeysValue: any, info: any) {
    const loop = (data: any) => {
      if (data.isNotHeaderPartial) return;

      this.updateCheckedTreeIds(data.id, info.checked);
      data.approvalRowIds.forEach((id: number) => {
        this.updateApprovalRowIds(id, info.checked);
      });

      if (data.children.length > 0) {
        data.children.forEach((el: any) => {
          if (el.isNotHeaderPartial) return;

          this.updateCheckedTreeIds(el.id, info.checked);
          el.approvalRowIds.forEach((id: number) => {
            this.updateApprovalRowIds(id, info.checked);
          });

          loop(el);
        });
      }
    };

    loop(info.node);
  }

  public updateCheckedTreeIds(id: number, checked: boolean) {
    if (checked) {
      !this.checkedNavigationTreeIds.has(id) && this.checkedNavigationTreeIds.add(id);
    } else {
      this.checkedNavigationTreeIds.has(id) && this.checkedNavigationTreeIds.delete(id);
    }
  }

  public updateApprovalRowIds(id: number, checked: boolean) {
    if (checked) {
      !this.checkedApprovalRowIds.has(id) && this.checkedApprovalRowIds.add(id);
    } else {
      this.checkedApprovalRowIds.has(id) && this.checkedApprovalRowIds.delete(id);
    }
  }

  public prepareParameterForSuggestion(data: IGetParameterGroupParametersDto[]): IParameterSuggestion[] {
    const res: IParameterSuggestion[] = [];

    data.forEach((el) => {
      res.push({
        hierarchy: [el.id],
        name: el.fullName,
        fullName: el.fullName,
        id: el.id,
        parameterIds: el.parameters.length === 0 ? null : el.parameters.map((parameter) => parameter.id),
        isGroup: true,
      });

      el.parameters.forEach((item) => {
        res.push({
          hierarchy: [el.id, item.id],
          id: item.id,
          name: item.name,
          fullName: item.fullName,
          dataType: item.dataType,
          groupId: el.id,
          parameterIds: [],
          value: '',
          isGroup: false,
          parameterListValues: [],
        });
      });
    });

    return res;
  }

  public createApprovalRowTree(dto: IGetApprovalRowDto[]): IApprovalRowTree[] {
    const transformListDto: IApprovalRowTree[] = [];
    const expandNodeIds = new Set<number>();

    const loop = (data: IGetApprovalRowDto[], parentId: number | null): IApprovalRowTree[] => {
      const values = data.map((item: IGetApprovalRowDto) => {
        const suggestionIds = new Set(item.children.filter((f) => f.structureType === RowStructureType.ParameterValue).map((_) => _.id));
        const hasConflict =
          this.conflictApprovalRowSuggestionIds.length > 0 && this.conflictApprovalRowSuggestionIds.some((id) => suggestionIds.has(id));

        const data: IApprovalRowTree = {
          key: item.id,
          title: item.originalText ? (item.headingOrder ? `${item.headingOrder} ${item.originalText}` : item.originalText) : item.suggestedText!,

          id: item.id,
          name: item.originalText ? item.originalText : item.suggestedText!,
          depth: item.depth,
          parentId: parentId,
          approvalRowIds: item.children
            .filter((f) => f.structureType === RowStructureType.ParameterValue && f.approvalRowSuggestions.length > 0)
            .map((value) => value.id),
          isNotHeaderPartial:
            item.children.length > 0 && item.structureType === RowStructureType.Header ? this.isEveryParameterNotPartial(item.children) : false,
          hasConflict: hasConflict,
          children: [],
        };

        transformListDto.push(data);

        if (hasConflict) {
          const parentNodeIds = Utils.getParentNodeIds(item.id, transformListDto);
          !expandNodeIds.has(item.id) && expandNodeIds.add(item.id);

          parentNodeIds.forEach((id: number) => {
            !expandNodeIds.has(id) && expandNodeIds.add(id);
          });
        }

        return {
          ...data,
          children: loop(
            item.children.filter((f) => f.structureType === RowStructureType.Header),
            item.id
          ),
        };
      });

      return this.isHideApproval ? values.filter((f) => !f.isNotHeaderPartial) : values;
    };

    const tree = loop(dto, null);

    this.conflictApprovalRowSuggestionIds && this.setExpandedKeys(Array.from(expandNodeIds));

    return tree;
  }

  public updateUserRoles() {
    const findPartialUserRoles = approvalStore.selectedApproval.partialApprovals.find(
      (el) => el.id === partialApprovalStore.selectedPartialApproval.id
    );

    partialApprovalStore.selectedPartialApproval.setUserRoles([
      ...(findPartialUserRoles?.userRoles ?? []),
      ...approvalStore.selectedApproval.userRoles,
    ]);

    partialApprovalStore.selectedPartialApproval.userRoles.forEach((_) => partialApprovalStore.selectedPartialApproval.setMyIterations(_));
  }

  public isEveryParameterNotPartial(children: IGetApprovalRowDto[]): boolean {
    return children.every((value) => {
      if (value.children.length > 0 && value.structureType === RowStructureType.Header) {
        return this.isEveryParameterNotPartial(value.children);
      }

      const isOpenAsSummaryApproval = approvalStore.approvalSets.length > 0 && window.location.pathname.includes(Routes.PARTIAL_APPROVALS_SETS);

      return isOpenAsSummaryApproval
        ? !approvalStore.partialApprovalSets.map((el) => el.id).includes(value.partialApprovalId) &&
            value.structureType === RowStructureType.ParameterValue
        : value.partialApprovalId !== partialApprovalStore.selectedPartialApproval.id && value.structureType === RowStructureType.ParameterValue;
    });
  }

  public async getParameterSuggestion() {
    if (!this.filterParameters.parameterGroupId) return;

    try {
      this.isLoadingParameter = true;
      const result = await ParameterGroupService.getAllParameterGroupParameters({
        parentId: this.filterParameters.parameterGroupId,
        includeRoot: this.filterParameters.includeRoot,
      });

      if (!result) return;

      runInAction(() => {
        this.parametersSuggestion = this.prepareParameterForSuggestion(result);
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} ParameterSuggestion`);
    } finally {
      this.isLoadingParameter = false;
    }
  }

  public async getApprovalRowList(): Promise<void> {
    if (!approvalStore.selectedApproval.id || !partialApprovalStore.selectedPartialApproval.id) return;

    const isOpenAsSummaryApproval = approvalStore.approvalSets.length > 0 && window.location.pathname.includes(Routes.PARTIAL_APPROVALS_SETS);

    try {
      this.isLoading = true;
      const result = await ApprovalService.getApprovalRows(
        approvalStore.selectedApproval.id,
        partialApprovalStore.selectedPartialApproval.id,
        isOpenAsSummaryApproval,
        this.isShowAllSuggestions
      );
      if (!result) return;
      const { approvalRowDtos, canGoToOnApprovalSkippingIterations } = result;

      const arr: IApprovalRow[] = [];

      const loop = (data: IGetApprovalRowDto[], parentId: number | null) => {
        data.forEach((row: IGetApprovalRowDto) => {
          const originalValue = row.originalText ? row.originalText : row.originalMultilineText;

          const obj: IApprovalRow = {
            id: row.id,
            depth: row.depth,
            originalValue:
              row.structureType === RowStructureType.Header && row.originalText && !Array.isArray(row.originalText)
                ? row.headingOrder
                  ? `${row.headingOrder} ${row.originalText}`
                  : originalValue
                : originalValue,
            suggestedValue: row.suggestedText ? row.suggestedText : row.suggestedMultilineText,
            parameter: row.parameter,
            parameterValueId: row.parameterValueId,
            approvalRowSuggestions: row.approvalRowSuggestions,
            hasComments: row.hasComments,

            hierarchy: row.structureType === RowStructureType.Header ? [row.id] : [parentId!, row.id],
            isGroup: row.structureType === RowStructureType.Header,
            hasPartialApprovalId: isOpenAsSummaryApproval
              ? approvalStore.partialApprovalSets.map((el) => el.id).includes(row.partialApprovalId)
              : partialApprovalStore.selectedPartialApproval.id === row.partialApprovalId,
            isNotHeaderPartial:
              row.children.length > 0 && row.structureType === RowStructureType.Header ? this.isEveryParameterNotPartial(row.children) : false,
            mySuggestion: row.approvalRowSuggestions.find((el) => el.user.id === usersStore.getUserData().id) ?? null,
          };

          arr.push(obj);

          loop(row.children, row.id);
        });
      };

      loop(approvalRowDtos, null);

      this.updateUserRoles();

      runInAction(() => {
        this.approvalRows = arr;
        this.approvalRowListDto = approvalRowDtos;
        this.canGoToOnApprovalSkippingIterations = canGoToOnApprovalSkippingIterations;
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} ApprovalRows`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createRowSuggestionApproval(): Promise<void> {
    try {
      this.isUpdate = true;

      await ApprovalRowSuggestionService.postApprovalRowSuggestion(this.approvalSuggestionRow.postRowSuggestionDto);
      this.setSuggestionDialogDialogOpen(false);
      this.approvalSuggestionRow.clear();
      this.getApprovalRowList();

      this.changeSuggestion(true);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} SuggestionApproval`);
    } finally {
      this.isUpdate = false;
    }
  }

  public async updateRowSuggestionApproval(): Promise<void> {
    if (!this.approvalSuggestionRow.putRowSuggestionDto || !this.approvalSuggestionRow.approvalRowSuggestionId) return;

    try {
      this.isUpdate = true;

      await ApprovalRowSuggestionService.putApprovalRowSuggestion(
        this.approvalSuggestionRow.approvalRowSuggestionId,
        this.approvalSuggestionRow.putRowSuggestionDto
      );

      this.approvalSuggestionRow.clear();
      this.getApprovalRowList();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} SuggestionApproval`);
    } finally {
      this.isUpdate = false;
    }
  }

  public async removeRowSuggestionApproval(id: number): Promise<void> {
    try {
      await ApprovalRowSuggestionService.deleteApprovalRowSuggestion(id);

      this.getApprovalRowList();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} SuggestionApproval`);
    } finally {
    }
  }

  public async rowSuggestionSetAccepted(payload: IPutRowSuggestionSetAcceptedDto): Promise<void> {
    try {
      await ApprovalRowSuggestionService.putRowSuggestionSetAccepted(payload);

      this.getApprovalRowList();
      this.changeSuggestion(true);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} SuggestionSetAccepted`);
    } finally {
    }
  }

  public async rowSuggestionSetAllAccepted(isAccepted: boolean): Promise<void> {
    try {
      const result = await ApprovalRowSuggestionService.putRowSuggestionSetAllAccepted({
        approvalRowIds: this.allApprovalRowIds,
        isAccepted: isAccepted,
      });

      if (result.length > 0) {
        toastStore.showWarning(Translator.translate('stores.allAcceptedWarningMessage'));
      }

      this.conflictApprovalRowSuggestionIds = result;

      this.getApprovalRowList();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} SuggestionSetAllAccepted`);
    } finally {
      this.checkedApprovalRowIds = new Set();
      this.checkedNavigationTreeIds = new Set();
    }
  }

  public async rowSuggestionDenyAll(id: number): Promise<void> {
    try {
      await ApprovalRowSuggestionService.putRowSuggestionDenyAll(id);

      this.getApprovalRowList();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} SuggestionDenyAll`);
    } finally {
    }
  }

  public clearConflictApprovalRow() {
    this.conflictApprovalRowSuggestionIds = [];
  }
}

export default new ApprovalSuggestionRowStore();
